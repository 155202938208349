import AddEditBillingPlan from '../billingPlan/addEditBillingPlan';
import ProjectMapping from './projectMapping';
import SubUnitMapping from './subUnitMapping';
import ComponentMapping from './componentMapping';
import componentPricingMapping from './componentPricingMapping';
import LeaseUnitMapping from './leaseUnitMapping';

export default {
  name: 'billingDetails',
  components: {
    AddEditBillingPlan,
    ProjectMapping,
    SubUnitMapping,
    ComponentMapping,
    componentPricingMapping,
    LeaseUnitMapping
  },
  data() {
    return {
      loader: false,
      activeTab: 'billingPlan',
      billingPlanCreated: true,
      plan_status: 'BPINCOMPLETE',
      planTypeCodeObj: this.$store.state.masterData.planType,
      editMode:false,
      isExternal:false,
      tabIndex:null
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        if(state.shared.actionName==='update'){
          this.editMode=true;
        }
        if(state.shared.actionName==='save'){
          this.editMode=false;
        }
      }
    });
    setTimeout(() => {
      if (this.$route.params.salesAgreementData) {
        this.tabIndex=2;
        this.activeTab = 'compPriceMapping'
      } 
    }, 0);
  },
  methods: {
    projectTypeStatus(type){
    this.isExternal=type[0].is_external;
    },
    setPlanType(planType){
      this.planTypeCodeObj=planType;
    },
    billingPlanTab(billingPlanInfo) {
      this.selectedTab(billingPlanInfo.tab);
      this.billingPlanCreated =
        billingPlanInfo.billing_plan_hdr_id > 0 ? true : false;
      this.plan_status = billingPlanInfo.planStatus;
    },
    selectedTab(tab) {
      this.editMode=false;
      this.activeTab = tab;
    },
    billingGrid() {
      this.$router.push('/dashboard/fms/master-data/billing-plan');
    },
    fmsCopyBillingPlan() {
      const payload = this.$route.params.billingPlanId
      this.loader = true;
      this.$store
        .dispatch('masterData/fmsCopyBillingPlan', payload )
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const newBillingPlanHdrId = response.data.data;
            this.$router.push('/dashboard/fms/master-data/billing-plan')
            setTimeout(()=>{
              this.$router.push(`/dashboard/fms/master-data/billing-plan-detail/${newBillingPlanHdrId}`);
            },200);
          } else{
            alert("Billing Plan Already Copied.");
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    copyBillingPlan() {
      const toCopy = confirm('Do you want to copy billing plan?')
      if(toCopy){
        this.fmsCopyBillingPlan();
      }
    }
  }
};
